/**
 *  Primabuild UI / Docs / Components / Navigation
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The navigation component
 *
 */

/** Dependencies */
import React                                        from "react";
import { Link, graphql, useStaticQuery }            from "gatsby";
import { ContentNavigation, ContentNavigationLink } from "@primabuild/ui";

/** Props */
interface NavigationProps {
    className? : string;
}

/** Navigation */
const navigationQuery = graphql`
    query Navigation {
        allMdx(sort: {fields: frontmatter___title}) {
            edges {
                node {
                    id
                    slug
                    frontmatter {
                        title
                        type
                    }
                }
            }
        }
    }
`;

export const Navigation : React.FunctionComponent<NavigationProps> = ( { className } ) => {

    // eslint-disable-next-line no-undef
    const navigation = useStaticQuery<GatsbyTypes.NavigationQuery>( navigationQuery );
    const nodes = navigation.allMdx.edges.map( ( { node } ) => node );

    const sections = nodes.reduce( ( sections : Array<string>, node ) => {
        const name = node.frontmatter?.type;
        if ( !name || sections.includes( name ) ) { return sections; }
        return sections.concat( [name] );
    }, ["Page"] );

    return (
        <div className={className}>
            {sections.map( name => (
                <ContentNavigation title={name === "Page" ? `${name}s` : name} key={name}>
                    {nodes.filter( node => node.frontmatter?.type === name )
                          .map( node => (
                              <ContentNavigationLink to={`/${node.slug || ""}/`}
                                                     activeClassName="active"
                                                     component={Link}
                                                     key={node.id}>
                                  {node.frontmatter?.title}
                              </ContentNavigationLink>
                          ) )}
                </ContentNavigation>
            ) )}
        </div>
    );
};

