/**
 *  Primabuild UI / Docs / Components / Syntax Highlighter
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Highlights the syntax of a code block
 *
 */

/** Dependencies */
import React          from "react";
import Highlight, {
    defaultProps,
    Language,
    PrismTheme as TypePrismTheme
}                     from "prism-react-renderer";
import classNames     from "classnames";

/** Dependencies */
import { PrismTheme } from "../PrismTheme/PrismTheme";

/** Dummy Theme for Prism React */
export const dummyTheme : TypePrismTheme = {
    plain : {},
    styles : []
};

interface SyntaxHighlighterProps {
    codeString : string;
    language : Language;
    className : string;
}

export const SyntaxHighlighter : React.FunctionComponent<SyntaxHighlighterProps> = (
    {
        codeString,
        className,
        language
    }
) => (
    <PrismTheme>
        <Highlight {...defaultProps} code={codeString} language={language}
                   theme={dummyTheme}>
            {( {
                   className : hClassName,
                   style,
                   tokens,
                   getLineProps,
                   getTokenProps
               } ) => (
                <pre className={classNames( className, hClassName )}
                     style={style}>
            {tokens.map( ( line, i ) => (
                <div key={i.toString()} {...getLineProps( { line, key : i } )}>
                    {line.map( ( token, key ) => (
                        <span key={key} {...getTokenProps( { token, key } )} />
                    ) )}
                </div>
            ) )}
          </pre>
            )}
        </Highlight>
    </PrismTheme>
);

