/**
 *  Primabuild UI / Docs / Components / Content Wrapper
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Content wrapper wraps the content in a boundary so it doesn't become full page
 *
 */

/** Dependencies */
import React       from "react";
import classNames  from "classnames";

/** Styles */
import * as styles from "./ContentWrapper.module.scss";

/** Props */
interface ContentWrapperProps {
    className? : string;
}

export const ContentWrapper : React.FunctionComponent<ContentWrapperProps> = (
    {
        children,
        className
    }
) => (
    <div className={classNames( styles.root, className )}>
        {children}
    </div>
);
