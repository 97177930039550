/**
 *  Primabuild UI / Docs / Components / Content
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Content is used to render the body of the documentation page in MDX
 *
 */

/* eslint-disable react/display-name */

/** Dependencies */
import React              from "react";
import { MDXProvider }    from "@mdx-js/react";
import {
    Heading,
    Paragraph,
    Separator,
    CardEmphasis,
    List,
    ListItem,
    Message,
    Table,
    TableHeadCell,
    TableRow,
    TableCell,
    Link
}                         from "@primabuild/ui";
import { BiInfoCircle }   from "react-icons/bi";
import classNames         from "classnames";
import { preToCodeBlock } from "mdx-utils";

/** Local Dependencies */
import { Divider }        from "../Divider/Divider";
import { Code }           from "../Code/Code";
import { LiveCode }       from "../LiveCode/LiveCode";

/** Styles */
import * as styles        from "./Content.module.scss";

/** Props */
interface ContentProps {}

export const Content : React.FunctionComponent<ContentProps> = ( { children } ) => (
    <MDXProvider components={{
        h1 : props => <Heading level="1"
                               className={classNames( styles.block )} {...props} />,
        h2 : props => <Heading level="2"
                               className={classNames( styles.block )} {...props} />,
        h3 : props => <Heading level="3"
                               className={classNames( styles.block )} {...props} />,
        h4 : props => <Heading level="4"
                               className={classNames( styles.block )} {...props} />,
        p : props => <Paragraph className={classNames( styles.block )} {...props} />,
        hr : props => <Separator className={classNames( styles.block )} {...props} />,
        ul : props => <List className={classNames( styles.block )} {...props} />,
        ol : props => <List ordered className={classNames( styles.block )} {...props} />,
        li : props => <ListItem {...props} />,
        table : props => <Table className={classNames( styles.block )} {...props} />,
        th : props => <TableHeadCell {...props} />,
        td : props => <TableCell {...props} />,
        tr : props => <TableRow {...props} />,
        a : props => <Link {...props} />,
        blockquote : ( { children, ...props } ) => (
            <Message icon={<BiInfoCircle/>}
                     className={classNames( styles.block )}
                     {...props}>{children}</Message>
        ),
        pre : ( preProps ) => {

            const props = preToCodeBlock( preProps );

            if ( !props || ((!props.language || !props.codeString) && !props.live) ) {
                return <Code className={classNames( styles.block )} {...preProps} />;
            }

            return props.live ? (
                <LiveCode className={classNames( styles.block )}
                          editor={props.editor !== "false"}
                          center={!!props.center}
                          background={props.background}
                          codeString={props.codeString}/>
            ) : (
                <Code highlight
                      className={classNames( styles.block )}
                      codeString={props.codeString}
                      language={props.language}/>
            );

        },
        inlineCode : props => <Code inline {...props} />,
        Box : CardEmphasis,
        Divider,
    }}>{children}</MDXProvider>
);




