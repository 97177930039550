/**
 *  Primabuild UI / Docs / Components / Prism Theme
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Renders the prism theme inside
 *
 */

/** Dependencies */
import React       from "react";
import classNames  from "classnames";

/** Styles */
import * as styles from "./PrismTheme.module.scss";

export const PrismTheme : React.FC = ( { children } ) => (
    <div className={classNames( styles.root )}>{children}</div>
);

