/**
 *  Primabuild UI / Docs / Layout
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Layout component for the documentation
 *
 */

/** Dependencies */
import React, { useState, useCallback }        from "react";
import classNames                              from "classnames";
import { Theme, ThemeProvider, Card, Heading } from "@primabuild/ui";
import Helmet                                  from "react-helmet";
import "normalize.css";
import "../../../dist/index.css";

/** Local Dependencies */
import { Navigation }                          from "../components/Navigation/Navigation";
import { Header }                              from "../components/Header/Header";
import { ContentWrapper }                      from "../components/ContentWrapper/ContentWrapper";

/** Styles */
import * as styles                             from "./index.module.scss";
import { Content }                             from "../components/Content/Content";

/** Props */
interface LayoutProps {
    pageContext : {
        frontmatter? : {
            title : string;
            type : string;
        }
    };
}

const Layout : React.FunctionComponent<LayoutProps> = ( { children, pageContext } ) => {

    const [theme, setTheme] = useState<Theme>( Theme.LIGHT );
    const handleThemeToggle = useCallback( () => {
        setTheme( theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT );
    }, [theme] );
    const { title, type } = pageContext.frontmatter || {};
    const documentTitle = (type === "Page" ? title : `${type} / ${title}`) +
        " - Primabuild UI";

    return (
        <ThemeProvider theme={theme}>
            <Helmet title={documentTitle}/>
            <Header onThemeToggle={handleThemeToggle} theme={theme}/>
            <div className={classNames( styles.body )}>
                <ContentWrapper className={classNames( styles.wrapper )}>
                    <Navigation className={classNames( styles.navigation )}/>
                    <Card className={classNames( styles.content )}>
                        <Heading level="1" className={classNames( styles.title )}>
                            {pageContext.frontmatter?.title}
                        </Heading>
                        <Content>{children}</Content>
                    </Card>
                </ContentWrapper>
            </div>
        </ThemeProvider>
    );
};

export default Layout;

