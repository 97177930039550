/**
 *  Primabuild UI / Docs / Components / Header
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The header
 *
 */

/** Dependencies */
import React              from "react";
import classNames         from "classnames";
import {
    Header as UIHeader,
    Heading,
    Toggle,
    Description,
    Theme,
}                         from "@primabuild/ui";
import { BiSun, BiMoon }  from "react-icons/bi";

/** Local Dependencies */
import { ContentWrapper } from "../ContentWrapper/ContentWrapper";
import { Logo }           from "../Logo/Logo";
import packageJson        from "../../../../package.json";

/** Styles */
import * as styles        from "./Header.module.scss";

/** Props */
interface HeaderProps {
    onThemeToggle? : () => void;
    theme? : Theme;
}

export const Header : React.FunctionComponent<HeaderProps> = (
    {
        onThemeToggle,
        theme = Theme.LIGHT
    }
) => (
    <UIHeader>
        <ContentWrapper className={classNames( styles.root )}>
            <div className={classNames( styles.logo )}>
                <Logo/>
            </div>
            <div className={classNames( styles.content )}>
                <Heading level="2" className={classNames( styles.title )}>
                    Primabuild UI
                </Heading>
                <Description className={classNames( styles.subtitle )}>
                    v{packageJson.version}
                </Description>
            </div>
            <div>
                <Toggle
                    icon={<BiSun/>}
                    iconChecked={<BiMoon/>}
                    onChange={onThemeToggle}
                    checked={theme === Theme.DARK}/>
            </div>
        </ContentWrapper>
    </UIHeader>
);
