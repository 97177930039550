/**
 *  Primabuild UI / Docs / Components / Logo
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The UI Logo
 *
 */

/** Dependencies */
import React from "react";

export const Logo = () => (
    <svg width="52px" height="52px" viewBox="0 0 52 52" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-78.000000, -36.000000)">
                <g transform="translate(78.000000, 36.000000)">
                    <circle stroke="currentColor" strokeWidth="4" cx="26" cy="26" r="24"/>
                    <circle stroke="currentColor" strokeWidth="3" cx="27" cy="26"
                            r="18.5"/>
                    <circle stroke="currentColor" strokeWidth="2" cx="28" cy="26" r="14"/>
                    <circle stroke="#FE6A07" strokeWidth="3" fill="#FE6A07" cx="28"
                            cy="26" r="8.5"/>
                </g>
            </g>
        </g>
    </svg>
);
