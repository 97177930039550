/**
 *  Primabuild UI / Docs / Components / Code
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Inline or block of code
 *
 */

/** Dependencies */
import React                 from "react";
import classNames            from "classnames";
import { CardEmphasis }      from "@primabuild/ui";
import { Language }          from "prism-react-renderer";

/** Styles */
import * as styles           from "./Code.module.scss";
import { SyntaxHighlighter } from "../SyntaxHighlighter/SyntaxHighlighter";

interface CodeProps {
    inline? : boolean;
    className? : string;
    highlight? : boolean;
    codeString? : string;
    language? : Language;
}

export const Code : React.FunctionComponent<CodeProps> = (
    {
        inline,
        children,
        codeString,
        language,
        highlight,
        className
    }
) => (
    <CardEmphasis
        className={classNames( styles.root, inline && styles.inline, className )}>
        {(highlight && codeString && language) ? (
            <SyntaxHighlighter codeString={codeString}
                               language={language}
                               className={classNames( styles.code )}/>
        ) : (
            <pre className={classNames( styles.code )}>{children}</pre>
        )}
    </CardEmphasis>
);

