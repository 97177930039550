/**
 *  Primabuild UI / Docs / Components / Live Code
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Live code component
 *
 */

/** Dependencies */
import React                                                from "react";
import classNames                                           from "classnames";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import { useMDXComponents }                                 from "@mdx-js/react";
import * as ui                                              from "@primabuild/ui";
import * as icons                                           from "react-icons/bi";

/** Local Dependencies */
import { dummyTheme }                                       from "../SyntaxHighlighter/SyntaxHighlighter";
import { PrismTheme }                                       from "../PrismTheme/PrismTheme";

/** Styles */
import * as styles                                          from "./LiveCode.module.scss";

/** Styles for the live editor */
const liveEditorStyles = {
    fontSize : "13px",
    fontFamily : "Source Code Pro, monospace",
    lineHeight : "18px",
};

interface LiveCodeProps {
    className? : string;
    codeString? : string;
    editor? : boolean;
    background? : string;
    center? : boolean;
}

export const LiveCode : React.FunctionComponent<LiveCodeProps> = (
    {
        codeString,
        className,
        editor = true,
        background = "card",
        center,
    }
) => {

    const components = useMDXComponents();

    return (
        <PrismTheme>
            <div className={classNames( styles.root, className )}>
                <LiveProvider code={codeString}
                              transformCode={code => "/** @tsx mdx */" + code}
                              theme={dummyTheme}
                              scope={{ ...components, ...ui, Icons : icons }}>
                    <LivePreview className={classNames(
                        styles.preview,
                        center && styles.center,
                        background === "layout" && styles.bgLayout
                    )}/>
                    {editor && (<LiveEditor className={classNames( styles.editor )}
                                            style={liveEditorStyles}/>)}
                    <LiveError className={classNames( styles.error )}/>
                </LiveProvider>
            </div>
        </PrismTheme>
    );
};
